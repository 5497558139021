import React, { FunctionComponent, useMemo } from 'react'
import classNames from 'classnames'
import Logo from 'components/Logo'
import LogoColor from 'components/LogoColor'
import styles from './LogoPoweredBy.module.scss'
import { useLocalize, AttachLocalizedList } from 'localize'
import locJson from './LogoPoweredBy.loc.json'
AttachLocalizedList(locJson)
interface Props {
  theme?: 'light' | 'dark' | 'color' | 'footer' | 'header' | 'subheader' | 'print'
  align?: 'center' | 'start' | 'end'
}

const LogoPoweredBy: FunctionComponent<Props> = ({ theme, align }) => {
  const { Localize } = useLocalize()

  const getLogo = () => {
    switch (theme) {
      case 'color':
        return <LogoColor hideTagline />
      default:
        return <Logo theme={theme} hideTagline />
    }
  }

  const xValue = useMemo(() => {
    if (align === 'start') {
      return '0%'
    } else if (align === 'end') {
      return '100%'
    }
    return '50%'
  }, [align])

  return (
    <div className={styles.logoWrapper}>
      <div className={styles.poweredByText}>
        <svg viewBox='0 0 276 30' xmlns='http://www.w3.org/2000/svg'>
          <text
            className={classNames(styles.svgText, {
              [styles.printSvgText]: theme === 'print',
              [styles.lightSvgText]: theme === 'light',
              [styles.footerSvgText]: theme === 'footer',
              [styles.headerSvgText]: theme === 'header',
              [styles.subheaderSvgText]: theme === 'subheader',
              [styles.startSvgText]: align === 'start',
              [styles.endSvgText]: align === 'end',
            })}
            y='25'
            x={xValue}
          >
            {Localize('LogoEducationPoweredBy')}
          </text>
        </svg>
      </div>
      <div className={styles.logo}>
        <span
          className={classNames(styles.logoImage, { [styles.lightLogoImage]: theme === 'light' })}
        >
          {getLogo()}
        </span>
      </div>
    </div>
  )
}

export default LogoPoweredBy

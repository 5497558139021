import { debugErrorLogger } from 'common'
import customFetch, { getFetchError } from './customFetch'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

export interface ComStatus {
  twilioIsOperational: boolean
  sendGridIsOperational: boolean
  twilioComponents?: ComStatusComponent[] | null
  sendGridComponents?: ComStatusComponent[] | null
  error: __FetchError
}
export interface ComStatusComponent {
  name: string
  status: string
}

export async function fetchCommunicationStatus(): Promise<ComStatus> {
  const startTime = new Date().getTime()
  const response = await customFetch('status/twilioSendGrid', { method: 'GET' })
  if (response?.ok) {
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog(
      [`Successfully posted login code in ${successfulFinishTime - startTime} ms`],
      { level: LogLevel.INFO },
    )
    const responseData = await response.json()
    return responseData
  }

  const error: __FetchError = getFetchError(response)
  debugErrorLogger('fetchCommunicationStatus failed with error', error)

  const errorResponse: ComStatus = {
    error,
    twilioIsOperational: false,
    sendGridIsOperational: false,
  }

  let err = error?.error != undefined ? error.error : 'Unknown Error'
  faro?.api?.pushLog([err], { level: LogLevel.INFO })

  return errorResponse
}

import React, { FunctionComponent, ReactNode } from 'react'
import styles from './IconLabel.module.scss'
import { Video, Article, Clock, PDF, Search, Phone, External, Location } from 'components/Icons'
import { useLocalize } from 'localize'
import classNames from 'classnames'

interface Props {
  className?: string
  label?: ReactNode[] | ReactNode | string
  contentType:
    | 'video'
    | 'article'
    | 'duration'
    | 'pdf'
    | 'search'
    | 'phone'
    | 'location'
    | 'external'
    | undefined
    | string
  size?: 'large' | 'normal' | 'medium' | 'small'
}

const IconLabel: FunctionComponent<Props> = ({
  className,
  label,
  contentType,
  size = 'normal',
}) => {
  const { Localize } = useLocalize()

  const getIcon = () => {
    switch (contentType) {
      case 'video':
        return Video
      case 'location':
        return Location
      case 'external':
        return External
      case 'phone':
        return Phone
      case 'duration':
        return Clock
      case 'in-house pdf':
        return PDF
      case 'search':
        return Search
      default:
        return Article
    }
  }
  const getLabelToUse = () => {
    if (label) {
      return label
    }
    switch (contentType) {
      case 'video':
        return Localize('Video')
      case 'duration':
        return Localize('Duration')
      case 'in-house pdf':
        return Localize('PDF')
      case 'search':
        return Localize('Search')
      default:
        return Localize('Article')
    }
  }

  const Icon = getIcon()
  const labelToUse = getLabelToUse()

  return (
    <span
      className={classNames(styles.iconLabelWrapper, 'hw-icon-label', className, {
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
      })}
    >
      <span className={styles.iconWrapper}>
        <Icon />
      </span>
      <span className={styles.iconLabel}>{labelToUse}</span>
    </span>
  )
}

export default IconLabel

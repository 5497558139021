import customFetch from './customFetch'
import { defaultOrganizationName } from 'config'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

export async function postJwtRefresh(
  setAuthToken?: (authToken: string | null, shouldSetUser?: boolean) => void,
  setErrors?: (errors: string[]) => void,
) {
  const startTime = new Date().getTime()
  const result = await customFetch('jwt/refresh', {
    method: 'POST',
  })

  if (result?.ok) {
    const authToken = await result.text()
    if (authToken && setAuthToken) {
      const successfulFinishTime = new Date().getTime()
      faro?.api?.pushLog([`Successfully refreshed jwt in ${successfulFinishTime - startTime} ms`], {
        level: LogLevel.INFO,
      })
      setAuthToken(authToken)
    } else {
      const problemWithRefreshMessage = 'Problem with refresh'
      faro?.api?.pushLog([problemWithRefreshMessage], { level: LogLevel.ERROR })
      setErrors?.([problemWithRefreshMessage])
    }
  }
}

export async function postJwtCreate(
  patientId: string,
  organizationId: string,
  subscriptionId: string,
  comMethod: string | string[],
  secretCode?: string,
  requester: string = defaultOrganizationName
): Promise<{ jwt: string; error?: __FetchError }> {
  const startTime = new Date().getTime()
  const body = {
    patientId,
    ComMethod: comMethod,
    Code: secretCode,
    JwtRequester: requester,
    organizationId: organizationId,
    subscriptionId: subscriptionId
  }

  const response = await customFetch(`jwt/create`, {
    method: 'POST',
    body: JSON.stringify(body),
  })

  if (response?.ok) {
    const jwt = await response.text()
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog([`Successfully created jwt in ${successfulFinishTime - startTime} ms`], {
      level: LogLevel.INFO,
    })
    return { jwt }
  }

  const errResponseStatus = response?.status
  const errResponseStatusText = response?.statusText
  faro?.api?.pushLog(
    [`Failed to create jwt. Status: ${errResponseStatus} ; Message${errResponseStatusText}`],
    { level: LogLevel.INFO },
  )
  return {
    jwt: '',
    error: {
      status: errResponseStatus,
      error: errResponseStatusText,
    },
  }
}

import customFetch, { getFetchError } from './customFetch'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

interface ErrorDiscoData extends __DiscoData {
  statusCode?: number
  error?: __FetchError
  responseStatus?: number
}

// !InHouseHack
export async function fetchDiscoContent(
  hwid: string,
  orgId: string,
  subId: string,
  localization?: __Localization | undefined,
  contentArtifact?: string | undefined,
  version?: string | undefined,
  isInHouse?: boolean,
): Promise<ErrorDiscoData> {
  const startTime = new Date().getTime()
  let url = `content/disco/${orgId}/${subId}/${hwid}`
  if (localization) {
    url += `/${localization}`
  }
  if (contentArtifact) {
    url += `/${contentArtifact}`
  }
  if (version) {
    url += `?version=${version}`
  }
  if (isInHouse) {
    if (url.includes('?')) {
      url += `&`
    } else {
      url += `?`
    }
    url += `inhouse=true`
  }

  const response = await customFetch(url)
  const responseStatus = response?.status || 0
  if (response?.ok) {
    const responseData = await response.json()
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog(
      [
        `Successfully fetched ${isInHouse ? 'in-house' : 'disco'} content in ${
          successfulFinishTime - startTime
        } ms`,
      ],
      { level: LogLevel.INFO },
    )
    return { ...responseData, responseStatus }
  }

  const error: __FetchError = getFetchError(response)

  const errorResponse: ErrorDiscoData = {
    id: hwid,
    lang: localization as string,
    docType: 'unknown',
    version: version || 'unknown',
    type: isInHouse ? 'In-House PDF' : 'unknown',
    title: { consumer: 'unknown' },
    responseStatus,
    error,
  }

  const err = errorResponse?.error?.error?.toString()
  if (err != null && err != undefined) faro?.api?.pushLog([err], { level: LogLevel.INFO })

  return errorResponse
}

import React, { FunctionComponent, SyntheticEvent } from 'react'
import styles from './ScrollTopIcon.module.scss'
import { ChevronUp } from 'components/Icons'
import { useLocalize, AttachLocalizedList } from 'localize'
import classNames from 'classnames'
import locJson from './ScrollTopIcon.loc.json'
import { useAppContext } from 'context/useAppContext'
AttachLocalizedList(locJson)
interface Props {
  size?: 'small' | 'normal'
}

const ScrollTopIcon: FunctionComponent<Props> = ({ size }) => {
  const { Localize } = useLocalize()
  const { userAppSettings } = useAppContext()

  return (
    <a
      href='#scroll-top'
      onClick={(e: SyntheticEvent) => {
        e.preventDefault()
        const $main = document.querySelector('main')
        if ($main) {
          if (!$main.getAttribute('tabindex')) {
            $main.setAttribute('tabindex', '-1')
          }
          $main.focus()
        } else {
          const $headerTitle = document.querySelector('h1')
          if ($headerTitle) {
            if (!$headerTitle.getAttribute('tabindex')) {
              $headerTitle.setAttribute('tabindex', '-1')
            }
            $headerTitle.focus()
          }
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }}
      className={classNames(styles.scrollIconBox, 'no-print', {
        [styles.animate]: !userAppSettings?.disableAnimation,
      })}
      title={Localize('ScrollTopIconText')}
      aria-label={Localize('ScrollTopIconText')}
    >
      <span className={styles.scrollIcon}>
        <ChevronUp />
      </span>
      {size === 'normal' ? (
        <span aria-hidden className={styles.scrollIconText}>
          {Localize('ScrollTopIconText')}
        </span>
      ) : null}
    </a>
  )
}

export default ScrollTopIcon

import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import styles from './CookieBanner.module.scss'
import { useLocalize, AttachLocalizedList } from 'localize'

import messageList from './CookieBanner.loc.json'
import { IconX } from 'components/Icons'
import ExternalLink from 'components/ExternalLink'
AttachLocalizedList(messageList)

const cookieBannerKey = 'HealthwiseCookieConsent'
const cookieBannerHiddenValue = 'true'

interface Props {
  children?: ReactNode[] | ReactNode | string | undefined
  className?: string
}

const CookieBanner: FunctionComponent<Props> = ({ className }) => {
  const { Localize } = useLocalize()
  const [hideCookieBanner, setHideCookieBanner] = useState<string | null>(null)
  useEffect(() => {
    setHideCookieBanner(localStorage.getItem(cookieBannerKey))
  }, [])

  const isHidden = useMemo<boolean>(() => {
    return hideCookieBanner === cookieBannerHiddenValue
  }, [hideCookieBanner])


  return (
    <div
      className={classNames(styles.hwCookieBanner, className, {
        [styles.hidden]: isHidden,
      })}
      role='alert'
      id='hw-cookie-banner'
      tabIndex={-1}
      aria-hidden={isHidden}
    >
      <div className={styles.hwCookieBannerOuter}>
        <div className={styles.hwCookieBannerInner}>
          <button
            type='button'
            className={styles.hwCookieBannerClose}
            aria-describedby='hw-cookie-banner-text'
            aria-label={Localize('CookieBannerAcknowledge')}
            title={Localize('CookieBannerAcknowledge')}
            id='hw-close-cookie-consent'
            onClick={() => {
              setHideCookieBanner(cookieBannerHiddenValue)
              localStorage.setItem(cookieBannerKey, cookieBannerHiddenValue)
            }}
          >
            <span aria-hidden='true'>
              <IconX />
            </span>
          </button>
          <div id='hw-cookie-banner-text' className={styles.hwCookieBannerText}>
            {Localize('CookieBannerPreText')}{' '}
            <ExternalLink
              data-testid='hw-cookie-banner-cookie-link'
              href={Localize('CookiePolicyLink')}
              className={styles.hwCookieBannerLink}
            >
              {Localize('CookiePolicy')}
            </ExternalLink>{' '}
            {Localize('and')}{' '}
            <ExternalLink
              data-testid='hw-cookie-banner-privacy-link'
              href={Localize('PrivacyPolicyLink')}
              className={styles.hwCookieBannerLink}
            >
              {Localize('PrivacyPolicy')}
            </ExternalLink>{' '}
            {Localize('CookieBannerPostText')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieBanner

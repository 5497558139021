import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import HeadTitle from 'components/HeadTitle'
import Footer from 'components/Footer'
import styles from './PageWrapper.module.scss'
import TimeoutWarning from 'components/TimeoutWarning'
import { useRouter } from 'next/router'
import { useAppContext } from 'context/useAppContext'
import { getTokenExpirationInfo } from 'token-utils'
import { testFeatureActive } from 'features'
import { useLocalize } from 'localize'
import { isInIframe } from 'common'
import CookieBanner from 'components/CookieBanner'
import classNames from 'classnames'
import ScrollTopIcon from 'components/ScrollTopIcon'
import { getLangFromLoc } from 'config'
import { useBranding } from 'brandingContext/useBrandingContext'

type Props = {
  children: React.ReactElement | string | (React.ReactElement | string)[] | undefined | null
  title?: string
  hideFooter?: boolean
  hideCopyright?: boolean
  hideTimeoutWarning?: boolean
  hideLogo?: boolean
  hideSkipLink?: boolean
  disableTimeout?: boolean
  showScrollIcon?: boolean
  skipFocusElement?: HTMLElement | null
}

let interval: NodeJS.Timeout = setInterval(() => {}, 100_000_000)

const PageWrapper: FC<Props> = ({
  children,
  title,
  hideFooter,
  hideCopyright,
  hideTimeoutWarning,
  hideLogo,
  hideSkipLink,
  disableTimeout,
  showScrollIcon,
  skipFocusElement,
}) => {
  const intervalTime: number = testFeatureActive('reduceTimeToTimeoutWarning') ? 5_000 : 30_000 // 30 seconds in milliseconds
  const { setErrors, authToken, isEmbedded, timeoutDateTime, userAppSettings } = useAppContext()
  const router = useRouter()
  const { cssProps } = useBranding()
  const [scrollIconVisible, setScrollIconVisible] = useState<boolean>(false)
  const { localization, changeLocalization, Localize, languageCode } = useLocalize()
  const [timeoutWarningActive, setTimeoutWarningActive] = useState<boolean>(false)
  const inIframe = isEmbedded || isInIframe()

  const brandedCssProps = useMemo(() => {
    let styleOverrides = ''
    if (cssProps) {
      const propKeys = Object.keys(cssProps || {})
      if (propKeys.length) {
        propKeys.forEach(key => {
          styleOverrides += `${key}: ${cssProps[key]};\n`
        })
      }
    }
    return styleOverrides
  }, [cssProps])

  const testLocalizeParameter = () => {
    const loc = router?.query?.loc
    if (loc && localization && loc !== localization) {
      const pathname = location.pathname
      const params = new URLSearchParams(location.search)
      params.delete('loc')
      const newSearch = params.toString()
      changeLocalization(`${loc}`)
      router.replace(`${pathname}${newSearch ? `?${newSearch}` : ''}`, undefined, {
        locale: getLangFromLoc(`${loc}`),
      })
    }
  }

  const handleShortcuts = (e: KeyboardEvent) => {
    if (
      (e.altKey && e.ctrlKey && e.key === 'f') ||
      (e.altKey && e.key === 'F') ||
      (e.altKey && e.shiftKey && (e.key === 'f' || e.key === 'F'))
    ) {
      router.push('/features', undefined, { shallow: true, locale: languageCode })
    }
  }

  const testToken = useCallback(() => {
    if (authToken) {
      const tokenInfo = getTokenExpirationInfo(authToken)
      if (tokenInfo.aboutToExpire && !timeoutWarningActive) {
        setTimeoutWarningActive(true)
        clearInterval(interval)
      } else if (!tokenInfo.aboutToExpire && timeoutWarningActive) {
        setTimeoutWarningActive(false)
      }
    }
  }, [authToken, timeoutWarningActive])

  const startTestTokenInterval = () => {
    if (!disableTimeout) {
      interval = setInterval(() => {
        testToken()
      }, intervalTime)
    }
  }

  // ********** Initialize
  useEffect(() => {
    startTestTokenInterval()
    setErrors?.([])
    testLocalizeParameter()
    const scrollHandler = () => {
      const scrollHeight = window.scrollY
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight

      if (documentHeight < windowHeight) {
        setScrollIconVisible(false)
      } else if (scrollHeight > windowHeight / 2) {
        setScrollIconVisible(true)
      } else {
        setScrollIconVisible(false)
      }
    }

    window.addEventListener('keydown', handleShortcuts)
    window.addEventListener('scroll', scrollHandler)

    return () => {
      clearInterval(interval)
      setErrors?.([])
      window.removeEventListener('keydown', handleShortcuts)
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  // *** Check localize Parameter ***
  useEffect(() => {
    testLocalizeParameter()
  }, [router?.query?.loc, localization])

  // *** Start the Gumball Tron ***
  useEffect(() => {
    startTestTokenInterval()
  }, [timeoutWarningActive])

  return (
    <>
      {brandedCssProps ? (
        <style>{`:root { 
        ${brandedCssProps}
      }`}</style>
      ) : null}
      <HeadTitle title={title} />
      {timeoutWarningActive && !disableTimeout ? (
        <TimeoutWarning
          onClose={() => {
            setTimeoutWarningActive(false)
          }}
          onTimeout={() => {
            setTimeoutWarningActive(false)
            router.push('/logout', undefined, {
              shallow: true,
              locale: languageCode,
            })
          }}
        />
      ) : null}
      <div className={styles.pageWrapper}>
        {inIframe || hideSkipLink || (
          <a
            href='#main'
            className={styles.skipContentLink}
            onClick={e => {
              e.preventDefault()
              let $defaultSkipFocusElement = document.querySelector('main')
              if (skipFocusElement?.focus) {
                $defaultSkipFocusElement = skipFocusElement
              }

              if ($defaultSkipFocusElement?.focus) {
                $defaultSkipFocusElement.focus()
              }
            }}
          >
            {Localize('SkipToContent')}
          </a>
        )}
        <div className={styles.pageWrapperContent}>
          {children}
          {showScrollIcon ? (
            <div
              className={classNames(
                styles.scrollTopIconWrapper,
                { [styles.scrollTopIconWrapperHidden]: !scrollIconVisible },
                { [styles.animate]: !userAppSettings?.disableAnimation },
              )}
            >
              <ScrollTopIcon size='small' />
            </div>
          ) : null}
        </div>
        {inIframe || hideFooter || (
          <Footer
            hideCopyright={hideCopyright}
            hideLogo={hideLogo && Object.keys(cssProps || {})?.length === 0}
            hideTimeoutWarning={hideTimeoutWarning}
            timeoutDateTime={timeoutDateTime}
          />
        )}
      </div>
      <CookieBanner />
    </>
  )
}

export default PageWrapper

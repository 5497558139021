import customFetch, { getFetchError } from './customFetch'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

interface ErrorUserAssignment extends __UserAssignment {
  error?: __FetchError
}

interface ErrorPaginatedAssignments extends __PaginatedAssignments {
  error?: __FetchError
}

interface ErrorAssignmentsData extends __UserAssignmentsData {
  error?: __FetchError
}

export async function fetchAssignments(assignmentId: string, organizationId: string, subscriptionId: string): Promise<ErrorUserAssignment> {
  const startTime = new Date().getTime()
  const response = await customFetch(`assignment/${assignmentId}/${organizationId}/${subscriptionId}`, { method: 'GET' })

  if (response?.ok) {
    try {
      const responseData = await response.json()
      const successfulFinishTime = new Date().getTime()
      faro?.api?.pushLog(
        [`Successfully fetched assignments in ${successfulFinishTime - startTime} ms`],
        { level: LogLevel.INFO },
      )
      return responseData
    } catch (err: any) {
      return {
        id: assignmentId,
        assignmentType: '',
        practitionerName: '',
        practitionerFHIRId: '',
        practitionerId: '',
        assignmentDate: '',
        content: [],
        error: err?.errorMessage || 'Unknown error',
      }
    }
  }

  const error: __FetchError = getFetchError(response)

  const errorResponse: ErrorUserAssignment = {
    id: assignmentId,
    assignmentType: '',
    practitionerName: '',
    practitionerFHIRId: '',
    practitionerId: '',
    assignmentDate: '',
    content: [],
    error,
  }

  const err = errorResponse?.error?.error?.toString()
  if (err != null && err != undefined) faro?.api?.pushLog([err], { level: LogLevel.ERROR })

  return errorResponse
}

export async function putAssignmentsInteraction(
  assignmentId: string,
  organizationId: string,
  subscriptionId: string,
  hwid: string,
  language: __Localization,
  status: __InteractionEventStatus,
): Promise<ErrorUserAssignment> {
  const startTime = new Date().getTime()
  const response = await customFetch(`assignments/interaction/${organizationId}/${subscriptionId}`, {
    method: 'PUT',
    body: JSON.stringify({ assignmentId, hwid, language, status }),
  })

  if (response?.ok) {
    const responseData = await response.json()
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog(
      [`Successfully updated assignment interactions in ${successfulFinishTime - startTime} ms`],
      { level: LogLevel.INFO },
    )
    return responseData
  }

  const error: __FetchError = getFetchError(response)

  const errorResponse: ErrorUserAssignment = {
    id: assignmentId,
    assignmentType: '',
    practitionerName: '',
    practitionerFHIRId: '',
    practitionerId: '',
    assignmentDate: '',
    content: [],
    error,
  }

  const err = errorResponse?.error?.error?.toString()
  if (err != null && err != undefined) faro?.api?.pushLog([err], { level: LogLevel.ERROR })

  return errorResponse
}

export async function fetchPatientAssignments(
  patientId: string,
  organizationId: string,
  subscriptionId: string,
  pageNumber?: number,
  pageSize?: number
): Promise<ErrorPaginatedAssignments> {
  const startTime = new Date().getTime()
  let url = `assignments/patientIdentifier/${patientId}/${organizationId}/${subscriptionId}`
  if (pageNumber) {
    url += `/${pageNumber}`
  }
  if (pageSize) {
    url += `/${pageSize}`
  }

  const response = await customFetch(url, {
    method: 'GET',
  })

  if (response?.ok) {
    const responseData = await response.json()
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog(
      [`Successfully fetched patient assignments in ${successfulFinishTime - startTime} ms`],
      { level: LogLevel.INFO },
    )
    return responseData
  }

  const error: __FetchError = getFetchError(response)

  const errorResponse: ErrorPaginatedAssignments = {
    page: pageNumber || 0,
    pageSize: pageSize || 0,
    resultCount: 0,
    results: [],
    isLastPage: true,
    lastpage: 1,
    error,
  }

  const err = errorResponse?.error?.error?.toString()
  if (err != null && err != undefined) faro?.api?.pushLog([err], { level: LogLevel.ERROR })

  return errorResponse
}

export async function fetchPatientAssignmentsData(
  assignmentId: string,
  organizationId: string,
  subscriptionId: string,
): Promise<ErrorAssignmentsData> {
  const startTime = new Date().getTime()
  const response = await customFetch(`patient/assignment/${assignmentId}/${organizationId}/${subscriptionId}`, {
    method: 'GET',
  })

  if (response?.ok && response?.status !== 204) {
    const responseData = await response.json()
    const successfulFinishTime = new Date().getTime()

    faro?.api?.pushLog(
      [`Successfully fetched patient assignment data in ${successfulFinishTime - startTime} ms`],
      { level: LogLevel.INFO },
    )
    if (responseData.practitionerId && responseData.hwOrganizationId && responseData.patientId) {
      return {
        assignmentId: responseData.assignmentId || assignmentId,
        patientId: responseData.patientId,
        practitionerFhirId: responseData.practitionerId,
        organizationId: responseData.hwOrganizationId,
        hwSubscriptionId: responseData.hwSubscriptionId,
      }
    }
  }

  const error: __FetchError = getFetchError(response)

  const errorResponse: ErrorAssignmentsData = {
    assignmentId,
    patientId: '',
    practitionerFhirId: '',
    organizationId: '',
    error,
  }

  const err = errorResponse?.error?.error?.toString()
  if (err != null && err != undefined) faro?.api?.pushLog([err], { level: LogLevel.ERROR })

  return errorResponse
}

import React, { FC, ReactNode, useEffect, useRef } from 'react'
import FocusLock, { AutoFocusInside } from 'react-focus-lock'
import styles from './ModalWrapper.module.scss'
import { IconX } from 'components/Icons'
import { contentScroll, getNewDashlessGuid } from 'common'
import { useLocalize } from 'localize'
import classNames from 'classnames'

type ThemeType = 'light' | 'medium-light' | 'medium-dark' | 'dark'

interface Props {
  title: string
  id?: string
  className?: string
  theme?: ThemeType
  onClose?: () => void
  onCloseFocusElement?: HTMLElement
  'disable-background'?: boolean
  'hide-close-button'?: boolean
  'disable-focus-lock'?: boolean
  children: ReactNode[] | ReactNode | string | undefined
  role?: 'dialog' | 'alertdialog'
}

const modalWrapperId = getNewDashlessGuid()

const ModalWrapper: FC<Props> = ({
  onClose,
  onCloseFocusElement,
  children,
  theme,
  title,
  className,
  id = modalWrapperId,
  role = 'dialog',
  ...otherProps
}) => {
  const { Localize } = useLocalize()
  const outerWrapper = useRef<HTMLDivElement>(null)
  const hideClose = otherProps['hide-close-button']
  const contentId = `${id}-content`

  const disableBackground = () => {
    if (otherProps['disable-background']) {
      contentScroll.disable()
    }
  }

  const enableBackground = () => {
    contentScroll.enable()
  }

  const closeMe = () => {
    enableBackground()
    onClose?.()
    if (typeof onCloseFocusElement?.focus === 'function') {
      setTimeout(() => {
        onCloseFocusElement.focus()
      }, 50)
    }
  }

  // ********** Initialize
  useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      const keyCode = e.key
      const esc = 'Escape'
      if (keyCode === esc) {
        closeMe()
      }
    }
    window.addEventListener('keydown', escapeListener)
    disableBackground()

    return () => {
      window.removeEventListener('keydown', escapeListener)
      enableBackground()
    }
  }, [])

  const getThemeClass = (theme: ThemeType = 'dark') => {
    switch (theme) {
      case 'light':
        return classNames(styles.modal_wrapper_light, `modal-wrapper-light`)
      case 'medium-light':
        return classNames(styles.modal_wrapper_medium_light, `modal-wrapper-medium-light`)
      case 'medium-dark':
        return classNames(styles.modal_wrapper_medium_dark, `modal-wrapper-medium-dark`)
      default:
        return classNames(styles.modal_wrapper_dark, `modal-wrapper-dark`)
    }
  }

  return (
    <FocusLock disabled={otherProps['disable-focus-lock']}>
      <div
        id={id}
        role={role}
        aria-label={title}
        ref={outerWrapper}
        className={classNames(
          `hw-modal-wrapper`,
          styles.modal_wrapper,
          getThemeClass(theme),
          className,
        )}
        onClick={closeMe}
        aria-describedby={contentId}
      >
        {hideClose ? (
          ''
        ) : (
          <AutoFocusInside>
            <button
              type='button'
              className={`hw-close-modal ${styles.modal_close}`}
              onClick={closeMe}
              title={Localize('Close')}
              aria-label={Localize('Close')}
              data-testid='hw-close-modal'
            >
              <IconX theme='light' />
            </button>
          </AutoFocusInside>
        )}
        <div id={contentId} className={styles.modal_content_wrapper}>
          {children}
        </div>
      </div>
    </FocusLock>
  )
}

export default ModalWrapper

import { debugErrorLogger } from 'common'
import customFetch from './customFetch'
import { faro, LogLevel } from '@grafana/faro-web-sdk'
const defaultStatus = 400

const validateHiveEvent = (event: __IHiveEvent): boolean => {
  return !!(event.sessionId && event.eventAction)
}

export type HiveEventPaths = 'search' | 'ratings' | 'content' | 'launch/application' // Add other paths when other events are created

export const postHiveEvent = (
  event: __IHiveEvent,
  eventPath: HiveEventPaths,
  successCallback?: (value: string | number | object | boolean, data?: __IHiveEvent) => void,
  failureCallback: (value: any) => void = debugErrorLogger,
) => {
  const startTime = new Date().getTime()
  if (validateHiveEvent(event)) {
    customFetch(`event/${eventPath}`, { body: JSON.stringify(event), method: 'POST' })
      .then(res => {
        if (res?.ok) {
          return res.text()
        }
        throw new Error(
          `Invalid event: ${event.eventAction} - Path: ${eventPath} - Status: ${res?.status || defaultStatus}`,
        )
      })
      .then(json => {
        const successfulFinishTime = new Date().getTime()
        faro?.api?.pushLog(
          [`Successfully posted event in ${successfulFinishTime - startTime} ms`],
          { level: LogLevel.INFO },
        )
        successCallback?.(json, event)
      })
      .catch(err => {
        const errorMessage = err.message
        faro?.api?.pushLog([errorMessage], { level: LogLevel.ERROR })
        failureCallback?.(errorMessage)
      })
  } else {
    const validationFailureMessage = "Event didn't validate"
    faro?.api?.pushLog([validationFailureMessage], { level: LogLevel.ERROR })
    failureCallback?.(validationFailureMessage)
  }
}

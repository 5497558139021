import { FunctionComponent } from 'react'
import { useLocalize } from 'localize'
import Head from 'next/head'

interface Props {
  title?: string
}

const HeadTitle: FunctionComponent<Props> = ({ title }) => {
  const { Localize, languageCode } = useLocalize()
  title = (title || Localize('Title')) + ' — Healthwise'
  return (
    <Head>
      <html lang={languageCode} />
      <title>{title}</title>
    </Head>
  )
}

export default HeadTitle

import React, { FunctionComponent, ReactNode, SyntheticEvent } from 'react'
import classNames from 'classnames'
import styles from './ExternalLink.module.scss'
import { useLocalize } from 'localize'
import { External } from 'components/Icons'

interface Props {
  href: string
  children: ReactNode[] | ReactNode | string | undefined
  hideIcon?: boolean
  useWarnDialog?: boolean
  className?: string
  title?: string
  target?: '_blank' | string
  hrefLang?: string
  media?: string
  type?: string
  rel?: string
  'data-testid'?: string
}

const ExternalLink: FunctionComponent<Props> = ({
  href,
  hideIcon = false,
  useWarnDialog = false,
  className,
  children,
  title,
  hrefLang,
  media,
  type,
  target = '_blank',
  rel = 'noopener noreferrer',
  ...otherProps
}) => {
  const { Localize } = useLocalize()


  return (
    <a
      href={href}
      title={title || Localize('ExternalLinkWarning')}
      className={classNames(styles.externalLink, className)}
      target={target}
      hrefLang={hrefLang}
      media={media}
      type={type}
      rel={rel}
      onClick={(e: SyntheticEvent) => {
        if (useWarnDialog && !confirm(Localize('ExternalLinkWarning'))) {
          e.preventDefault()
          return false
        }
        return true
      }}
      data-testid={otherProps['data-testid']}
    >
      <span className={styles.externalLinkText}>{children}</span>
      <span className='offscreen-text'>{Localize('ExternalLinkWarning')}</span>
      {hideIcon ? null : (
        <>
          {' '}
          <span className={styles.externalLinkIconWrapper}>
            <External />
          </span>
        </>
      )}
    </a>
  )
}

export default ExternalLink

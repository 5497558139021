import customFetch from './customFetch'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

export async function postLoginCode(
  patientId: string,
  channel: string,
  organizationName: string,
  locale: string,
  organizationId: string,
  subscriptionId: string, 
): Promise<string> {
  const startTime = new Date().getTime()
  const response = await customFetch(`login/code`, {
    method: 'Post',
    body: JSON.stringify({
      patientId,
      channel,
      organizationName,
      locale,
      organizationId,
      subscriptionId
    }),
  })

  if(response?.status === 429)
  {
    return 'too many requests'
  }

  if (response?.ok) {
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog(
      [`Successfully posted login code in ${successfulFinishTime - startTime} ms`],
      { level: LogLevel.INFO },
    )
    return await response.text()
  }

  faro?.api?.pushLog([`Failed to post login code due to error: ${response?.statusText}`], {
    level: LogLevel.ERROR,
  })

  return 'unknown'
}

export async function postLoginEpic(
  baseUrl: string,
  codeToken: string,
  clientId: string | undefined,
  client_iss: string,
  organizationId: string,
  subscriptionId: string
): Promise<{ authToken: string; demo: boolean; error?: __FetchError }> {
  const response = await customFetch('login/mychart', {
    method: 'POST',
    body: JSON.stringify({
      redirect_uri: baseUrl + '/epic',
      token: codeToken,
      client_id: clientId || '',
      client_iss: client_iss || '',
      organizationId,
      subscriptionId
    }),
  })

  if (response?.ok) {
    const token = await response?.text()
    return {
      authToken: token,
      demo: false,
    }
  }

  return {
    authToken: '',
    demo: false,
    error: {
      status: response?.status,
      error: response?.statusText,
    },
  }
}

export async function logout(): Promise<boolean> {
  const response = await customFetch('logout')
  return response?.ok || false
}

import React, { FC, useEffect, useState } from 'react'
import styles from './TimeoutWarning.module.scss'
import ModalWrapper from 'components/ModalWrapper'
import { setPageTitle } from 'common'
import Link from 'next/link'
import { useAppContext } from 'context/useAppContext'
import { useLocalize, AttachLocalizedList } from 'localize'
import { postJwtRefresh } from 'apis'
import locJson from './TimeoutWarning.loc.json'
import { getTokenExpirationInfo } from 'token-utils'
AttachLocalizedList(locJson)

interface Props {
  onClose?: () => void
  onTimeout?: () => void
}

const oneSecond = 1000
const backupTimeoutTime = 30 // seconds

const TimeoutWarning: FC<Props> = ({ onClose, onTimeout }) => {
  const [countdownSeconds, setCountdownSeconds] = useState<number>(backupTimeoutTime * oneSecond)
  const { setErrors, setAuthToken, authToken } = useAppContext()
  const [existingPageTitle, setExistingPageTitle] = useState<string>('')
  const { Localize, languageCode } = useLocalize()
  let warningInterval: NodeJS.Timeout = setInterval(() => {}, 0)

  const handleClose = () => {
    clearInterval(warningInterval)
    onClose?.()
  }

  useEffect(() => {
    setExistingPageTitle(document.title)
  }, [])

  useEffect(() => {
    if (authToken) {
      const tokenInfo = getTokenExpirationInfo(authToken)
      if (typeof tokenInfo.secondsRemaining === 'number') {
        setCountdownSeconds(tokenInfo.secondsRemaining)
      }
    }
  }, [authToken])

  useEffect(() => {
    warningInterval = setInterval(() => {
      if (countdownSeconds > 0) {
        setCountdownSeconds(countdownSeconds - 1)
      } else {
        clearInterval(warningInterval)
        onTimeout?.()
      }
    }, oneSecond)

    if (existingPageTitle) {
      setPageTitle(Localize('TimeoutWarningTitle', countdownSeconds, existingPageTitle))
    }

    return () => {
      clearInterval(warningInterval)
    }
  }, [countdownSeconds])

  const tokenRefresh = async () => {
    if (setErrors && authToken && setAuthToken) {
      postJwtRefresh((authToken: string | null, shouldSetUser?: boolean) => {
        setCountdownSeconds(0)
        setAuthToken?.(authToken, shouldSetUser)
        handleClose()
      }, setErrors)
    }
  }
  return (
    <div className={styles.app}>
      <ModalWrapper
        theme='light'
        onClose={handleClose}
        disable-background
        title={Localize('TimeoutWarningDialogTitle')}
        role='alertdialog'
      >
        <div
          className={styles.app_main}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <p>
            {`${Localize('TimeoutWarningTimeoutPreLink')} `}
            <Link
              href='/logout'
              as={`/logout`}
              data-testid='hw-timeout-warning-logout'
              locale={languageCode}
            >
              {Localize('TimeoutWarningLogoutNowText')}
            </Link>
            <span>{` ${Localize('or')} `}</span>
            <a
              href='#do-nothing'
              onClick={e => {
                e.preventDefault()
                tokenRefresh()
                return false
              }}
              data-testid='hw-timeout-warning-refresh'
            >
              {Localize('TimeoutWarningRefreshLinkText')}
            </a>

            {`. ${Localize('TimeoutWarningTimeoutPostText')} `}
            <span role='timer'>
              {`${countdownSeconds} ${Localize(
                'TimeoutWarningTimeoutTimingUnits',
                countdownSeconds !== 1,
              )}.`}
            </span>
          </p>
        </div>
      </ModalWrapper>
    </div>
  )
}

export default TimeoutWarning

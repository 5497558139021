import React, { FunctionComponent, useMemo } from 'react'
import classNames from 'classnames'

import LogoPoweredBy from 'components/LogoPoweredBy'
import { useLocalize, AttachLocalizedList } from 'localize'
import { useBranding } from 'brandingContext/useBrandingContext'
import styles from './Footer.module.scss'
import { productCopyrightYearSpan } from 'config'
import ExternalLink from 'components/ExternalLink'
import IconLabel from 'components/IconLabel'
import locJson from './Footer.loc.json'

AttachLocalizedList(locJson)

interface Props {
  hideLogo?: boolean | undefined
  hideCopyright?: boolean | undefined
  hideTimeoutWarning?: boolean | undefined
  timeoutDateTime?: Date | undefined
}

const Footer: FunctionComponent<Props> = ({
  hideLogo = false,
  hideCopyright = false,
  hideTimeoutWarning = false,
}) => {
  const { Localize, localization } = useLocalize()
  const { getBrandingProp, brandingProps } = useBranding()

  const clientInfo = useMemo(() => {
    return {
      logo: getBrandingProp?.('footer-logo') || '',
      printLogo: getBrandingProp?.('print-logo') || '',
      contactMeLink: getBrandingProp?.('client-contact-us-link') || '',
      contactPhone: getBrandingProp?.('client-contact-phone') || '',
      contactPhoneLink: getBrandingProp?.('client-contact-phone-link') || '',
      address: getBrandingProp?.('client-contact-address') || '',
    }
  }, [brandingProps])

  const hasClientInfo = useMemo(() => {
    return Boolean(
      clientInfo.address || clientInfo.contactPhone || clientInfo.contactMeLink || clientInfo.logo,
    )
  }, [clientInfo])

  if (!localization) {
    return (
      <footer className={classNames(styles.footer, { [styles.noCopyright]: hideCopyright })}>
        <div className={styles.legalText}>
          {!hideCopyright && (
            <div className={styles.copyright}>{`©${productCopyrightYearSpan}`}</div>
          )}
        </div>
      </footer>
    )
  }

  return (
    <footer
      className={classNames(styles.footer, {
        [styles.noCopyright]: hideCopyright,
      })}
      data-testid='hw-footer'
    >
      <div
        className={classNames(styles.innerFooter, {
          [styles.hasClientInfo]: hasClientInfo,
        })}
      >
        {hasClientInfo ? (
          <div className={styles.clientInfo}>
            {clientInfo.logo ? (
              <>
                <div className={classNames(styles.clientInfoLogo, 'no-print')}>
                  <img src={clientInfo.logo} alt='' />
                </div>
                <div className={classNames(styles.clientInfoLogo, 'print-only')}>
                  <img src={clientInfo.printLogo} alt='' />
                </div>
              </>
            ) : null}
            {clientInfo.contactMeLink ? (
              <div className={classNames(styles.clientInfoItem, 'no-print')}>
                <IconLabel
                  contentType='external'
                  size='medium'
                  label={
                    <ExternalLink
                      className={styles.clientLink}
                      href={clientInfo.contactMeLink}
                      data-testid='hw-footer-client-contact-us-link'
                      hideIcon
                    >
                      {Localize('FooterContactUs')}
                    </ExternalLink>
                  }
                />
              </div>
            ) : null}
            {clientInfo.contactPhone ? (
              <div className={styles.clientInfoItem}>
                <IconLabel
                  size='medium'
                  contentType='phone'
                  label={
                    <a
                      className={styles.clientLink}
                      href={clientInfo.contactPhoneLink}
                      data-testid='hw-footer-client-phone-link'
                    >
                      {clientInfo.contactPhone}
                    </a>
                  }
                />
              </div>
            ) : null}
            {clientInfo.address ? (
              <div className={styles.clientInfoItem}>
                <IconLabel size='medium' contentType='location' label={clientInfo.address} />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={classNames(styles.legalText)}>
          {!hideLogo && (
            <>
              <div className={classNames(styles.logoWrapperTop, 'no-print')}>
                <ExternalLink
                  className={classNames(styles.logoLink)}
                  href={Localize('AboutHealthwiseUrl')}
                  hideIcon
                >
                  <LogoPoweredBy theme='footer' align='start' />
                </ExternalLink>
              </div>
              <div className={classNames(styles.logoWrapperTop, 'print-only')}>
                <div className={classNames(styles.logoLink)}>
                  <LogoPoweredBy theme='print' align='start' />
                </div>
              </div>
            </>
          )}
          <div className={styles.legalLinks}>
            <ExternalLink
              className={styles.legalLink}
              href={Localize('TermsOfUseLink')}
              data-testid='link-terms-of-use'
            >
              {Localize('TermsOfUse')}
            </ExternalLink>
            <ExternalLink
              className={styles.legalLink}
              href={Localize('PrivacyPolicyLink')}
              data-testid='link-privacy-policy'
            >
              {Localize('PrivacyPolicy')}
            </ExternalLink>
            <ExternalLink
              className={styles.legalLink}
              href={Localize('CookiePolicyLink')}
              data-testid='link-cookie-policy'
            >
              {Localize('CookiePolicy')}
            </ExternalLink>
          </div>
          {!hideCopyright && (
            <div className={styles.copyright}>
              {`©${productCopyrightYearSpan} ${Localize('FooterCopyright')} `}
              {!hideTimeoutWarning && <span>{Localize('TimeoutWarning')}</span>}
            </div>
          )}
          {!hideTimeoutWarning && hideCopyright && (
            <div className={styles.copyright}> {Localize('TimeoutWarning')}</div>
          )}
        </div>
        {!hideLogo && (
          <>
            <div className={classNames(styles.logoWrapper, 'no-print')}>
              <ExternalLink
                className={classNames(styles.logoLink)}
                href={Localize('AboutHealthwiseUrl')}
                hideIcon
              >
                <LogoPoweredBy theme='footer' />
              </ExternalLink>
            </div>
            <div className={classNames(styles.logoWrapper, 'print-only')}>
              <div className={classNames(styles.logoLink)}>
                <LogoPoweredBy theme='print' />
              </div>
            </div>
          </>
        )}
      </div>
    </footer>
  )
}

export default Footer

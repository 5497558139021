import { testFeatureActive } from 'features'
import customFetch, { getFetchError } from './customFetch'
import { faro, LogLevel } from '@grafana/faro-web-sdk'

interface ErrorCommunicationsOptions extends __CommunicationOptions {
  error?: __FetchError
}

export async function fetchCommunicationsOptions(
  patientId: string,
  organizationId: string,
  subscriptionId: string
): Promise<ErrorCommunicationsOptions> {
  const forceCommunicationFailure = testFeatureActive('forceCommunicationFailure')
  const startTime = new Date().getTime()
  const response = await customFetch(`communications/patientIdentifier/${patientId}/${organizationId}/${subscriptionId}`)
  if (response?.ok && !forceCommunicationFailure) {
    const successfulFinishTime = new Date().getTime()
    faro?.api?.pushLog(
      [`Successfully fetched communication options in ${successfulFinishTime - startTime} ms`],
      { level: LogLevel.INFO },
    )
    return await response.json()
  }

  const error: __FetchError = forceCommunicationFailure
    ? { status: 500, error: 'Emulating communication failure' }
    : getFetchError(response)

  const errorResponse: ErrorCommunicationsOptions = {
    patientId,
    hasEmail: false,
    hasPhoneNumber: false,
    maskedEmail: '',
    maskedPhoneNumber: '',
    error,
  }

  const err = errorResponse?.error?.error?.toString()
  if (err != null && err != undefined) faro?.api?.pushLog([err], { level: LogLevel.INFO })

  return errorResponse
}
